import { useState } from "react";

export default initialSettingss => {
  const [settings, setSettings] = useState(initialSettingss);
  return {
    settings,
    setSearchSimilarityBy: searchSimilarityBy => {
      setSettings({ ...settings, searchSimilarityBy });
    },
    setNumberResultsToReturn: numberResultsToReturn => {
      setSettings({ ...settings, numberResultsToReturn });
    },
    setAnswerOnly: answerOnly => {
      setSettings({ ...settings, answerOnly });
    }
  };
};
