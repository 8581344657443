import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../images/logo.png';

const Footer = () => (
  <footer className='page-footer grey lighten-3'>
    <div>
      <div className='row'>
        <div className='col l2 s12 center-align'>
          <h5 className='black-text'>
            <a href='/' className='brand-logo'>
              <img
                src={logo}
                alt='Unsplashed background img 1'
                width='70'
                height='70'
              />
            </a>
          </h5>
        </div>
        <div className='col l7 s12'>
          <h6 className='black-text'>Company Bio</h6>

          <p className='black-text text-lighten-4'>
            BioRhythm Technologies is an AI-focused consulting firm that builds
            machine learning solutions to maximize business success for
            companies and organizations across wide range of industries. Areas
            of focus include computer vision, NLP, and predictive analytics.
          </p>
        </div>

        <div className='col l3 s12 center-align'>
          <i className='material-icons prefix black-text text-lighten-4'>
            location_on
          </i>
          <address className='black-text text-lighten-4'>
            BioRhythm Technologies, LLC
            <br />
            181 New Road
            <br />
            Suite 304 #2
            <br />
            Parsippany, NJ 07054
            <br />
            USA
          </address>
        </div>
      </div>
    </div>
    <div className='footer-copyright grey lighten-2 center-align'>
      <div className='container black-text text-lighten-3'>
        <small>
          Made by <Link to={'/contact'}>BioRhythm Technologies</Link> | &copy;
          Copyright {new Date().getFullYear()}, BioRhythm Technologies. All
          rights reserved.
        </small>

        <div class='footer-social-icons'>
          <ul class='social-icons'>
            {/*<li>
              <a href='https://www.facebook.com/' class='social-icon'>
                <i class='fa fa-facebook'></i>
              </a>
            </li>*/}
            <li>
              <a href='https://twitter.com/BiorhythmLabs' class='social-icon'>
                <i class='fa fa-twitter'></i>
              </a>
            </li>
            {/*<li>
              <a href='' class='social-icon'>
                <i class='fa fa-rss'></i>
              </a>
            </li>
            */}
            {/*<li>
              <a href='https://www.youtube.com/' class='social-icon'>
                <i class='fa fa-youtube'></i>
              </a>
            </li>*/}
            <li>
              <a
                href='https://www.linkedin.com/company/biorhythm-technologies'
                class='social-icon'
              >
                <i class='fa fa-linkedin'></i>
              </a>
            </li>
            <li>
              <a
                href='https://github.com/BiorhythmTechnologies'
                class='social-icon'
              >
                <i class='fa fa-github'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
