import React from 'react';

const Products = () => (
  <div>
    <h1>Skin Analyzer</h1>
    <h1>Dr. Siraj</h1>
  </div>
);

export default Products;
