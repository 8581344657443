import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Header from "./components/header/header.component";
import Spinner from "./components/spinner/spinner.component";
import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import { selectCurrentUser } from "./redux/user/user.selectors";
import { checkUserSession } from "./redux/user/user.actions";
import MainHeader from "./components/Header";
import Footer from "./components/Footer";
import Landing from "./components/pages/Landing";
import About from "./components/pages/About";
import Services from "./components/pages/Services";
import Products from "./components/pages/Products";
import SkinAnalyzer from "./components/products/skin-analyzer/skin-analyzer.component";
import ContactUs from "./components/contact/ContactUs";
import Donate from "./pages/donate/Donate";
import Chatbot from "./components/chatbot/Chatbot";
import { ThemeProvider } from "./components/contexts/ThemeContext";
import { LanguageProvider } from "./components/contexts/LanguageContext";
import { RequestTypeProvider } from "./components/contexts/RequestTypeContext";
import { GlobalStyle } from "./components/global.styles";
import "./App.css";

const HomePage = lazy(() => import("./pages/homepage/homepage.component"));
const ChatWindow = lazy(() =>
  import("./components/siraj/chat-window.component")
);
const ShopPage = lazy(() => import("./pages/shop/shop.component"));
const SignInAndSignUpPage = lazy(() =>
  import("./pages/sign-in-and-sign-up/sign-in-and-sign-up.component")
);
const CheckoutPage = lazy(() => import("./pages/checkout/checkout.component"));

const isSirajFreeApp = true;
const trialPeriodInDays = 30;

const numDaysBetween = (d1, d2) => {
  const diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};

const App = ({ checkUserSession, currentUser }) => {
  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);

  const today = new Date();
  let signUpDate = new Date();
  if (currentUser && currentUser.signUpDate) {
    signUpDate = new Date(currentUser.signUpDate);
  }

  const daysRemaining = Math.ceil(trialPeriodInDays - numDaysBetween(today, signUpDate));

  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <div className="container z-depth-2">
                {/* <MainHeader /> */}
                <Route
                  exact
                  path="/"
                  render={() => (
                    <>
                      <MainHeader />
                      <Landing />
                      <Footer />
                      <Chatbot />
                    </>
                  )}
                />

                <Route
                  exact
                  path="/about"
                  render={() => (
                    <>
                      <MainHeader />
                      <About />
                      <Footer />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/services"
                  render={() => (
                    <>
                      <MainHeader />
                      <Services />
                      <Footer />
                    </>
                  )}
                />

                <Route
                  exact
                  path="/products"
                  render={() => (
                    <>
                      <Header />
                      <Products />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/products/skan"
                  render={() => (
                    <>
                      <MainHeader />
                      <SkinAnalyzer />
                    </>
                  )}
                />

                <Route
                  exact
                  path="/contact"
                  render={routeProps => (
                    <>
                      <MainHeader />
                      <ContactUs {...routeProps} />
                      <Footer />
                    </>
                  )}
                />

                <Route
                  exact
                  path="/donate"
                  render={routeProps => (
                    <>
                      <MainHeader />
                      <Donate {...routeProps} />
                      <Footer />
                    </>
                  )}
                />
              </div>
              <div className="container">
                <Route
                  exact
                  path="/products/siraj-home"
                  render={() =>
                    currentUser && currentUser.isPaidCustomer ? (
                      <Redirect to="/products/siraj" />
                    ) : (
                      <>
                        <Header />
                        <HomePage />
                        {/* <div
                          style={{
                            margin: "20px auto",
                            height: "50%",
                            width: "87%",
                            boxShadow: "4px 4px 8px 1px rgba(172, 168, 165, 0.6)"
                          }}
                        >
                          <Footer />
                        </div> */}
                      </>
                    )
                  }
                />

                <Route
                  exact
                  path="/shop"
                  render={routeProps => (
                    <>
                      <Header />
                      <ShopPage {...routeProps} />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/checkout"
                  render={() =>
                    currentUser ? (
                      currentUser.isPaidCustomer ? (
                        <Redirect to="/products/siraj" />
                      ) : (
                        <>
                          <Header />
                          <CheckoutPage />
                        </>
                      )
                    ) : (
                      <Redirect to="/signin" />
                    )
                  }
                />
                
                <Route
                  exact
                  path="/products/siraj-demo"
                  render={() =>
                  (
                        <ThemeProvider>
                          <LanguageProvider>
                            <RequestTypeProvider>
                              <Header />
                              <ChatWindow isGuestDemo />
                            </RequestTypeProvider>
                          </LanguageProvider>
                        </ThemeProvider>
                  )} />

                <Route
                  exact
                  path="/products/siraj"
                  render={() =>
                    currentUser ? (
                      currentUser.isPaidCustomer ? (
                        <ThemeProvider>
                          <LanguageProvider>
                            <RequestTypeProvider>
                              <Header />
                              <ChatWindow />
                            </RequestTypeProvider>
                          </LanguageProvider>
                        </ThemeProvider>
                      ) : isSirajFreeApp ? (
                        <ThemeProvider>
                          <LanguageProvider>
                            <RequestTypeProvider>
                              <Header trialEndsIn={daysRemaining} />
                              <ChatWindow trialEndsIn={daysRemaining} />
                            </RequestTypeProvider>
                          </LanguageProvider>
                        </ThemeProvider>
                      ) : (
                        <Redirect to="/shop" />
                      )
                    ) : (
                      <Redirect to="/signin" />
                    )
                  }
                />
                <Route
                  exact
                  path="/signin"
                  render={() =>
                    currentUser ? (
                      <Redirect to="/products/siraj" />
                    ) : (
                      <>
                        <Header />
                        <SignInAndSignUpPage />
                      </>
                    )
                  }
                />
              </div>
            </Suspense>
          </ErrorBoundary>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  checkUserSession: () => dispatch(checkUserSession())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
