import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 67,
    textDecoration: "none"
  },
  inputLabel: {
    color: "#444",
    fontFamily: "Open Sans Condensed"
  }
}));

export default function ProductsMenu(props) {
  const classes = useStyles();
  const [product, setProdcut] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    setProdcut(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <form autoComplete="off">
      <FormControl className={classes.formControl} disableunderline="true" >
        <InputLabel
          className={classes.inputLabel}
          htmlFor="demo-controlled-open-select"
        >
          Products
        </InputLabel>
        <Select
          disableUnderline={true}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={product}
          onChange={handleChange}
          inputProps={{
            name: "product",
            id: "demo-controlled-open-select"
          }}
        >
          <MenuItem value={"/products/skan"}>
            <Link to="/products/skan">Skin Analyzer</Link>
          </MenuItem>
          <MenuItem value={"/products/siraj-home"}>
            <Link to="/products/siraj-home">Dr. Siraj</Link>
          </MenuItem>
        </Select>
      </FormControl>
    </form>
  );
}
