import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Parallax, Background } from 'react-parallax';

import 'font-awesome/css/font-awesome.min.css';
import background1 from '../../images/background1.jpg';
import background2 from '../../images/background2.jpg';

class Landing extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div>
        <div id='index-banner' className='parallax-container'>
          <div className='section no-pad-bot'>
            <div className='container'>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
          <Parallax
            bgImage={background1}
            bgImageAlt='background img 1'
            strength={300}
          >
            <div style={{ height: '400px' }} />
          </Parallax>
        </div>

        <div>
          <div className='section'>
            <div className='row'>
              <div className='col s12 m4'>
                <div className='icon-block'>
                  <h3 className='center blue-text text-lighten-1'>
                    <i className='fa fa-android' />
                  </h3>
                  <h5 className='center'>AI-Powered Medical Chatbots</h5>

                  <p className='light'>
                    Conversational medical chatbots that can be used to assist
                    in repetitive tasks, thus reducing costs and improving
                    quality of service by making such technologies accessible
                    for more people, including for those living in rural areas.
                    For example, bots can be used in following up with patients
                    which will mean better patient care and will eventually
                    decrease readmission rate. Bots can also be used to do a
                    preliminary diagnosis based on the symptoms the patient has
                    provided, perform sentiment analysis to detect patient
                    emotions, and even analyze both heart and lung sounds
                    remotely in order to detect any abnormalities, and the list
                    can go on!
                  </p>
                </div>
              </div>

              <div className='col s12 m4'>
                <div className='icon-block'>
                  <h3 className='center blue-text text-lighten-1'>
                    <i className='fa fa-heartbeat' />
                  </h3>
                  <h5 className='center'>Medical Diagnosis</h5>

                  <p className='light'>
                    Breakthroughs in artificial intelligence and deep learning,
                    accompanied with the increasing availability of training
                    data can help with clinical decision making when it comes to
                    assessing risks and diagnosing certain diseases.For example,
                    AI based medical image processing algorithms can be used to
                    detect suspicious lesions in MRI, CT or X - ray readings,
                    thus helping with the early detection of certain cancer
                    types.Advancements in cameras used in mobile devices, on the
                    other hand, will potentially make it possible for regular
                    users to have access to such technologies on large scale.
                  </p>
                </div>
              </div>

              <div className='col s12 m4'>
                <div className='icon-block'>
                  <h3 className='center blue-text text-lighten-1'>
                    <i className='fa fa-users' />
                  </h3>
                  <h5 className='center'>Virtual Assistants</h5>

                  <p className='light'>
                    Voice or text-enabled Virtual Assistants can help both
                    healthcare providers and patients in a number of ways. For
                    example, patients and elderly people can ask medical
                    questions pertaining to their condition and receive answers
                    in real time. They can even receive recommendations based on
                    their condition, reminders for any of their prescribed
                    medications, or they can even book an appointment with the
                    nearest healthcare provider if needed. Healthcare providers,
                    on the other hand, would be able to go through patient'
                    records in order to track their progress and can even get in
                    touch with them for any medication related enquiries and can
                    advise if any preventive health screening is needed or not,
                    etc.
                  </p>
                </div>
              </div>
            </div>
            <div className='row center'>
              <a
                href={'http://dreve.biorhythm.io/'}
                className='btn-large waves-effect waves-light red lighten-1 btn'
              >
                <i class='material-icons left'>face</i>Try a Demo
              </a>
            </div>
          </div>
        </div>

        <div className='parallax-container index-banner'>
          <Parallax
            bgImage={background2}
            bgImageAlt='background img 2'
            strength={300}
          >
            <div style={{ height: '400px', paddingTop: '150px' }}>
              <div className='section'>
                <div className='container'>
                  <div className='row center'>
                    <h5
                      className='header col s12 light black-text text-darken-2'
                      style={{
                        backgroundColor: 'rgb(183, 210, 255, 0.2)',
                      }}
                    >
                      Artificial Intelligence powered solutions revolutionizing
                      the healthcare industry!
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>

        <div>
          <div className='section'>
            <div className='row'>
              <div className='col s12 center'>
                <h3>
                  <i className='mdi-content-send brown-text' />
                </h3>
                <h4>Why Us</h4>
                <p className='left-align light'>
                  Fueled by advances in the cloud and cognitive computing,
                  Artificial Intelligence is bound to become a transformational
                  force in healthcare industry due to its advantages when it
                  comes to traditional analytics and clinical decision-making
                  techniques. We use advanced technologies to get things done.
                  We’re using the latest cloud technologies to provide you with
                  the best solutions in the market today.
                </p>
              </div>
            </div>

            <div className='row center'>
              <Link
                to={'/contact'}
                id='download-button'
                className='btn-floating btn-large waves-effect waves-light blue'
              >
                <i class='material-icons'>mail_outline</i>Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
