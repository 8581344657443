import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from "react-redux";
import axios from "axios";

import logo from '../../assets/logo.png';
import { userUpdateStart } from "../../redux/user/user.actions";
import { clearCart } from "../../redux/cart/cart.actions.js";

import './stripe-button.css';

const StripeCheckoutButton = ({ label, price, userUpdateStart, clearCart, history }) => {
  const priceForStripe = price * 100;
  // const publishableKey = "pk_test_D1IBiOGrFs54Ij6vDLIx8bUy003T8keNmv";
  const publishableKey = "pk_live_VYlYF5501SZHFLeIBQ3tHX7300pIBr4XCX";

  const onToken = token => {
    axios({
      url: "/api/payment",
      method: "post",
      data: {
        amount: priceForStripe,
        token: token
      }
    })
      .then(response => {
        const purchasedAt = new Date();

        userUpdateStart({
          productName: "siraj",
          purchaseDate: purchasedAt,
          purchasePrice: priceForStripe,
          isPaidCustomer: true
        });
        alert("succesful payment");
        clearCart();
        if (history) {
          history.push("/products/siraj");
        }
      })
      .catch(error => {
        console.error("Payment Error: ", error);
        alert(
          "There was an issue with your payment! Please make sure you use the provided credit card."
        );

        if (history) {
          history.push("/checkout");
        }
      });
  };

  return (
      <StripeCheckout
        // label="Pay Now"
        label={label}
        name="BiorRhythm Technologies LLC."
        billingAddress
        shippingAddress
        image={logo}
        description={`Your total is $${price}`}
        amount={priceForStripe}
        panelLabel="Pay Now"
        token={onToken}
        stripeKey={publishableKey}
      />
  );
};


const mapDispatchToProps = dispatch => ({
  userUpdateStart: updatesObj => dispatch(userUpdateStart(updatesObj)),
  clearCart: () => dispatch(clearCart())
});

export default connect(
  null,
  mapDispatchToProps
)(StripeCheckoutButton);
