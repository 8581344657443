import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import DonationOptions from "./DonationOptions";
import StripeCheckoutButton from "../../components/stripe-button/stripe-button.component";
import {
  CheckoutPageContainer,
  TotalContainer,
  WarningContainer
} from "../checkout/checkout.styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function SimpleSelect() {
  const classes = useStyles();
  const [amount, setAmount] = React.useState(0);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const handleChange = event => {
    setAmount(event.target.value);
  };

  const inputStyle =
    amount !== 0
      ? { margin: "0 auto", width: "100%" }
      : {
          margin: "0 auto",
          width: "100%",
          pointerEvents: "none",
          opacity: "0.4"
        };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography component="div" style={{ height: "80vh" }}>
          <CheckoutPageContainer>
            <WarningContainer>
              Please consider supporting us in order for us to be able to
              sustain and expand our services.
              <DonationOptions setAmount={setAmount} />
              <TotalContainer
                style={{
                  color: "grey"
                }}
              >
                TOTAL: ${amount}
              </TotalContainer>
              <div style={inputStyle}>
                <StripeCheckoutButton
                  label="Donate Now"
                  price={amount}
                  disabled={true}
                />
              </div>
            </WarningContainer>
          </CheckoutPageContainer>
        </Typography>
      </Container>
    </div>
  );
}
