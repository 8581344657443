import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
         border-top-left-radius: 5px;
         border-top-right-radius: 5px;
         border: 0.5px dotted grey;
         border-bottom: none;
         height: 60px;
         width: 87%;
         display: flex;
         margin: 0 auto;
         justify-content: space-between;
         margin-bottom: 8px;
         opacity: 0.9;
         padding-bottom: 2px;
         margin-bottom: 0;

         @media screen and (max-width: 800px) {
           height: 55px;
           width: 83%;
           font-size: 0.7rem;
           padding: 10px;
           margin-bottom: 0;

           // margin-top: 25px;
           // margin-bottom: 5px;
         }
       `;

export const LogoContainer = styled(Link)`
  width: 40px;
  padding-top: 15px;
  padding-left: 10px;

  @media screen and (max-width: 800px) {
    width: 25px;
    padding: 0;
    margin-top: 7px;
  }
`;

export const OptionsContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 800px) {
    width: 80%;
  }
`;

export const OptionLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
`;

OptionLink.displayName = 'OptionLink';
