import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CartIcon from '../cart-icon/cart-icon.component';
import CartDropdown from '../cart-dropdown/cart-dropdown.component';
import { selectCartHidden } from '../../redux/cart/cart.selectors';
import { selectCurrentUser} from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import { ReactComponent as Logo } from '../../assets/profile.svg';

import {
  HeaderContainer,
  LogoContainer,
  OptionsContainer,
  OptionLink
} from './header.styles';

export const Header = ({ currentUser, hidden, signOutStart, trialEndsIn }) => (
         <HeaderContainer>
           <LogoContainer to="/">
             <Logo />
           </LogoContainer>
           <OptionsContainer>
             {!currentUser || (currentUser && !currentUser.isPaidCustomer) ? (
               <>
                 <OptionLink to="/shop">BUY</OptionLink>
                 <span style={{ color: "red", fontSize: "0.9rem" }}>
                   {`${
                     trialEndsIn !== undefined
                       ? trialEndsIn > 0
                         ? `Your trial period will end in ${trialEndsIn} day(s)`
                         : "Your trial period has ended!"
                       : ""
                   }`}
                 </span>
               </>
             ) : null}
             {currentUser && currentUser.isPaidCustomer ? (
               <OptionLink to="/products/siraj">DR. SIRAJ</OptionLink>
             ) : null}
             {currentUser ? (
               <OptionLink as="div" onClick={signOutStart}>
                 SIGN OUT
               </OptionLink>
             ) : (
               <OptionLink to="/signin">SIGN IN</OptionLink>
             )}

             {!currentUser || (currentUser && !currentUser.isPaidCustomer) ? (
               <CartIcon />
             ) : null}
           </OptionsContainer>
           {hidden ? null : <CartDropdown />}
         </HeaderContainer>
       );

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
