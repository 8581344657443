import React from "react";
import { FaCamera, FaPlay, FaRegWindowClose, FaPause } from "react-icons/fa";

import "./video.styles.scss";

class Video extends React.Component {
  videoRef = React.createRef();
  canvasRef = React.createRef();
  errorRef = React.createRef();

  componentDidMount() {
    this.playVideo();
  }

  handleSuccess = async stream => {
    const video = this.videoRef.current;
    video.style.display = "inline-block";
    window.stream = stream;
    video.srcObject = stream;
    video.play();
  };

  playVideo = async () => {
    const constraints = (window.constraints = {
      audio: false,
      video: true
    });
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.handleSuccess(stream);
    } catch (err) {
      this.handleVideoError(err);
    }
  };

  stopVideo = () => {
    try {
      const video = this.videoRef.current;
      video.style.display = "none";
      video.pause();
      video.src = "";
      window.stream.getTracks()[0].stop();
      this.props.closeVideo();
    } catch (err) {
      console.error(err);
      this.props.closeVideo();
    }
  };

  pauseVideo = () => {
    const video = this.videoRef.current;
    video.pause();
  };

  resumeVideo = () => {
    const video = this.videoRef.current;
    video.play();
  };

  async takePhoto() {
    const video = this.videoRef.current;
    const canvas = this.canvasRef.current;

    this.props.freezeFrame(video, canvas);
    this.stopVideo(video);
    this.props.diagnosePictureTaken();
  }

  errorMsg = (msg, error) => {
    const errorElement = this.errorRef.current;
    errorElement.innerHTML += `<p>${msg}</p>`;
    if (typeof error !== "undefined") {
      console.error(error);
    }
  };

  handleVideoError = error => {
    const constraints = window.constraints;

    if (error.name === "ConstraintNotSatisfiedError") {
      let v = constraints.video;
      this.errorMsg(
        `The resolution ${v.width.exact}x${
          v.height.exact
        } px is not supported by your device.`
      );
    } else if (error.name === "PermissionDeniedError") {
      this.errorMsg(
        "Permissions have not been granted to use your camera and " +
          "microphone, you need to allow the page access to your devices in " +
          "order for the demo to work."
      );
    }
    this.errorMsg(`getUserMedia error: ${error.name}`, error);
  };

  render() {
    return (
      <div className="video-container">
        <video
          id="video"
          width="640"
          height="480"
          style={{ display: "none" }}
          ref={this.videoRef}
        />
        <canvas
          className="video-canvas"
          ref={this.canvasRef}
          width="640"
          height="480"
        />
        <div id="errorMsg" ref={this.errorRef} />

        <div className="buttons-container">
          <button onClick={() => this.takePhoto()}>
            <FaCamera />
          </button>
          <button onClick={() => this.stopVideo()}>
            <FaRegWindowClose />
          </button>
          <button onClick={() => this.pauseVideo()}>
            <FaPause />
          </button>
          <button onClick={() => this.resumeVideo()}>
            <FaPlay />
          </button>
        </div>
      </div>
    );
  }
}

export default Video;
