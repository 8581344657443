import React from "react";

import diagnose from '../diagnosis-utils';
import "./real-time.styles.scss";

class RealTime extends React.Component {
  constructor(props) {
    super(props);
  }
  videoRef = React.createRef();
  canvasRef = React.createRef();

  async componentDidMount() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user"
          }
        })
        .then(stream => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        });
      Promise.all([webCamPromise])
        .then(values => {
          this.detectFrame(this.videoRef.current);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  stopRealTime = async () => {
    try {
      const video = this.videoRef.current;
      const canvas = this.canvasRef.current;
      video.style.display = "none";
      video.pause();
      window.stream.getTracks()[0].stop();

      this.props.freezeFrame(video, canvas);
      this.props.closeRealTime();
      this.props.diagnose();
    } catch (err) {
      console.log(err);
      this.props.closeRealTime();
    }

  };

  detectFrame = async (video) => {
    if (video) {
      let predictions = await diagnose(video);
      this.renderPredictions(predictions);
      requestAnimationFrame(() => {
        this.detectFrame(video);
      });
    }
  };

  renderPredictions = predictions => {
    if (this.canvasRef && this.canvasRef.current) {
      const ctx = this.canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const font = "16px sans-serif";
      ctx.font = font;
      ctx.textBaseline = "top";

      predictions.forEach(prediction => {
        const x = 10;
        const y = 80;
        const width = 570;
        const height = 350;

        ctx.strokeStyle = "#33FF9C";
        ctx.lineWidth = 4;
        ctx.strokeRect(x, y, width, height);

        ctx.fillStyle = "#33FF9C";
        const textWidth = ctx.measureText(prediction.className).width;
        const textHeight = parseInt(font, 10);
        ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
      });


      let x = 2;
      let y = 60;

      x = x + 30;
      y = y + 50;
      ctx.fillStyle = "#00FFFF";
      ctx.fillText("1.   " + predictions[0].className + " " + predictions[0].probability, x, y);

      y = y + 20;
      ctx.fillText("2.   " + predictions[1].className + " " + predictions[1].probability, x, y);

      y = y + 20;
      ctx.fillText("3.   " + predictions[2].className + " " + predictions[2].probability, x, y);
    }
  };

  render() {
    return (
      <div className="realtime-container" onClick={() => this.stopRealTime()}>
        <video
          className="realtime-video"
          autoPlay
          playsInline
          muted
          ref={this.videoRef}
          width="640"
          height="480"
        >
        </video>
        <canvas
          className="realtime-video"
          ref={this.canvasRef}
          width="640"
          height="480"
        />
      </div>
    );
  }
}

export default RealTime;
