import * as tf from "@tensorflow/tfjs";
import swal from "sweetalert";

import SKIN_CLASSES from "./data/skin_classes";


const diagnose = async (imgToDiagnose, modelToUse) => {
  const img = imgToDiagnose || document.getElementById("tf-image");
  const model = modelToUse || await tf.loadLayersModel("/kaggle/model.json");

  let tensor = tf
    .browser
    .fromPixels(img)
    .resizeNearestNeighbor([224, 224])
    .toFloat();

  let offset = tf.scalar(127.5);

  tensor = tensor
    .sub(offset)
    .div(offset)
    .expandDims();

  let predictions = await model.predict(tensor).data();

  let top5 = Array.from(predictions)
    .map(function (p, i) {
      return {
        probability: p,
        className: SKIN_CLASSES[i].name,
        description: SKIN_CLASSES[i].description
      };
    })
    .sort(function (a, b) {
      return b.probability - a.probability;
    })
    .slice(0, 5);
  setTimeout(() => {
    if (predictions === null) {
      swal(
        "Something went wrong...",
        "Please try again or try another image!",
        "error"
      ).then(() => {
        window.location.reload();
      });
    }
  }, 3000);

  return top5;
};


export default diagnose;
