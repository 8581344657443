import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyCuFMmTkXU8y7MOEqQu_Ywmd4cTBnecJsQ",
  authDomain: "biobert-2503e.firebaseapp.com",
  databaseURL: "https://biobert-2503e.firebaseio.com",
  projectId: "biobert-2503e",
  storageBucket: "-2503e.appspot.com",
  messagingSenderId: "80152927017",
  appId: "1:80152927017:web:1d4ac12fb2c09311bb7538",
  measurementId: "G-5JTVPQLCPK"
};

firebase.initializeApp(config);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    const isPaidCustomer = false;

    const today = new Date();
    const signUpDate = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        isPaidCustomer,
        signUpDate,
        ...additionalData
      });
    } catch (error) {
      console.error("error creating user", error.message);
    }
  }

  return userRef;
};

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = firestore.collection(collectionKey);

  const batch = firestore.batch();
  objectsToAdd.forEach(obj => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};

export const convertCollectionsSnapshotToMap = collections => {
  const transformedCollection = collections.docs.map(doc => {
    const { title, items } = doc.data();

    return {
      routeName: encodeURI(title.toLowerCase()),
      id: doc.id,
      title,
      items
    };
  });

  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[collection.title.toLowerCase()] = collection;
    return accumulator;
  }, {});
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const updateCurrentUser = async updatesObj => {
  auth.onAuthStateChanged(async userAuth => {
    let userRef = await firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();
    if (snapShot.exists) {
      try {
        await userRef.set(updatesObj.payload, { merge: true });
      } catch (error) {
        console.error("error updating user", error.message);
      }
    }
    return userRef;
  });
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;
