import React, { Component } from "react";
import axios from "axios/index";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.send_email_call = this.send_email_call.bind(this);
    this._handleFormChange = this._handleFormChange.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      foundMethod: "",
      commPref: ""
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  async send_email_call() {
    const errors = [];

    if (!this.state.firstName) {
      errors.push("- First Name can't be empty\n");
    }

    if (!this.state.lastName) {
      errors.push("- Last Name can't be empty\n");
    }

    if (!this.state.email) {
      errors.push("- Email can't be empty\n");
    }

    if (!this.state.phone) {
      errors.push("- Phone can't be empty\n");
    }

    if (!this.state.message) {
      errors.push("- Message can't be empty\n");
    }

    if (errors.length) {
      window.alert(`Missing required field(s):\n ${errors.join("")}`);
      return;
    }

    try {
      const request = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        company: this.state.company,
        message: this.state.message,
        foundMethod: this.state.foundMethod,
        commPref: this.state.commPref
      };
      const config = {};

      const res = await axios.post("/api/sendEmail", request, config);
    } catch (e) {
      console.error(e);
    }
    this.props.history.push("/");
  }

  _handleFormChange(event) {
    event.preventDefault();

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div
        className="card-panel container light-blue lighten-5"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          padding: "50px",
          opacity: "0.9"
        }}
      >
        <div className="row">
          <div className="col m10 offset-m1 s12">
            <h3 className="center-align">
              Reach out to us and let us know how we can help
            </h3>
            <br />
            <p className="center-align">
              We will walk you through the options and answer any questions you
              might have.
            </p>
            <br />
            <div className="row">
              <form className="col s12">
                <div className="row">
                  <div className="input-field col m6 s12">
                    <i className="material-icons prefix">account_circle</i>

                    <input
                      id="first_name"
                      name="firstName"
                      type="text"
                      className="validate"
                      maxlength="50"
                      required
                      onChange={this._handleFormChange}
                      value={this.state.firstName}
                    />
                    <label for="first_name">
                      First Name<sup>*</sup>
                    </label>
                  </div>

                  <div className="input-field col m6 s12">
                    <i className="material-icons prefix">account_circle</i>

                    <input
                      id="last_name"
                      name="lastName"
                      type="text"
                      className="validate"
                      maxlength="50"
                      required
                      onChange={this._handleFormChange}
                      value={this.state.lastName}
                    />
                    <label for="last_name">
                      Last Name<sup>*</sup>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col m6 s12">
                    <i className="material-icons prefix">mail_outline</i>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="validate"
                      required
                      maxlength="50"
                      onChange={this._handleFormChange}
                      value={this.state.email}
                    />
                    <label for="email">
                      Email<sup>*</sup>
                    </label>
                  </div>
                  <div className="input-field col s6">
                    <i className="material-icons prefix">phone</i>
                    <input
                      id="icon_telephone"
                      name="phone"
                      type="tel"
                      className="validate"
                      maxlength="50"
                      required
                      onChange={this._handleFormChange}
                      value={this.state.phone}
                    />
                    <label for="icon_telephone">
                      Phone<sup>*</sup>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col m6 s12">
                    <i className="material-icons prefix">business</i>
                    <input
                      id="company"
                      name="company"
                      type="text"
                      className="validate"
                      maxlength="50"
                      onChange={this._handleFormChange}
                      value={this.state.company}
                    />
                    <label for="company">Company</label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <i className="material-icons prefix">mode_edit</i>

                    <textarea
                      id="message"
                      name="message"
                      className="materialize-textarea"
                      maxlength="250"
                      required
                      onChange={this._handleFormChange}
                      value={this.state.message}
                    />
                    <label for="message">
                      Message<sup>*</sup>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <div className="input-field col m6 s12">
                      <i className="material-icons prefix">hearing</i>
                      <label for="found-method">
                        How did you hear about us?
                      </label>
                      <br />
                      <br />
                      <select
                        className="browser-default"
                        id="found-method"
                        name="foundMethod"
                        onChange={this._handleFormChange}
                        value={this.state.foundMethod}
                      >
                        <option value="" selected disabled>
                          Choose your option
                        </option>
                        <option value="Google">Google</option>
                        <option value="Customer">Customer</option>
                        <option value="Other..">Other..</option>
                      </select>
                    </div>

                    <div className="input-field col m6 s12">
                      <i className="material-icons prefix">sort</i>

                      <label for="comm-pref">Communication Preferences</label>
                      <br />
                      <br />
                      <select
                        className="browser-default"
                        id="comm-pref"
                        name="commPref"
                        onChange={this._handleFormChange}
                        value={this.state.commPref}
                      >
                        <option value="" selected disabled>
                          Choose your option
                        </option>
                        <option value="Email">Email</option>
                        <option value="Call Me">Call Me</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col m12">
                    <p className="center-align">
                      <button
                        className="btn btn-large waves-effect waves-light"
                        type="button"
                        name="action"
                        onClick={this.send_email_call}
                      >
                        Send Message
                      </button>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactUs;
