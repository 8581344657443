import React from "react";

import "./Spinner.css";

const Spinner = props => {
	return (
		<div className="spinner-container">
			<div className="spinner-div">
				<div className="spinner">
				</div>
			</div>
			<p className="loader-message">{props.text}</p>
		</div>
	)
}

export default Spinner;
