import React from "react";
import Particles from "react-particles-js";
import { FaUpload, FaCameraRetro, FaVideo } from "react-icons/fa";
import swal from "sweetalert";

import diagnose from "./diagnosis-utils";
import "./skin-analyzer.styles.css";
import Spinner from "./spinner/Spinner";
import Video from "./video/video.component.jsx";
import RealTime from "./real-time/real-time.component";
import PredictionList from "./prediction-list/prediction-list.component";
import Modal from "./modal/model.component";
import sampleImg from "./images/sampleImg.jpg";
import Logo from "./Logo/Logo";
import SKIN_CLASSES from "./data/skin_classes";

export default class SkinAnalyzer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileToUpload: null,
      isLoading: false,
      predictions: this.loadInitialPredictions(),
      showVideo: false,
      showRealTime: false
    };

    this.closeVideo = this.closeVideo.bind(this);
    this.closeRealTime = this.closeRealTime.bind(this);
    this.freezeFrame = this.freezeFrame.bind(this);
  }

  loadInitialPredictions() {
    const initialPredictions = [];
    for (const item in SKIN_CLASSES) {
      initialPredictions.push({
        className: "Calculating:  " + SKIN_CLASSES[item].name,
        probability: 0,
        description: SKIN_CLASSES[item].description
      });
    }
    return initialPredictions;
  }

  divImageRef = React.createRef();
  tfImageRef = React.createRef();

  async componentDidMount() {
    this.getPredictions(this.tfImageRef.current);
  }

  showVideo() {
    this.setState({
      showVideo: true
    });
  }

  showRealTime() {
    this.setState({
      showRealTime: true
    });
  }

  closeVideo() {
    this.setState({
      showVideo: false
    });
  }

  closeRealTime() {
    this.setState({
      showRealTime: false
    });
  }

  fileSelectorHandler = async e => {
    this.setState({
      isLoading: true,
      predictions: this.loadInitialPredictions()
    });
    const file = e.target.files[0];
    if (file === undefined || file.size > 10 ** 7) {
      this.setState({ isLoading: false });
    } else {
      await this.setState({
        fileToUpload: file
      });
      const ext = file.name.split(".").reverse()[0];
      if (ext === "jpg" || ext === "jpeg" || ext === "png") {
        this.readFile();
      } else {
        swal(
          "Wrong file type!",
          "Please choose an image (.jpg or png).",
          "error"
        );
        this.setState({ isLoading: false });
      }
    }
  };

  readFile = async result => {
    const selectedFile = this.state.fileToUpload;
    const reader = new FileReader();
    const imgtag = this.divImageRef.current;
    imgtag.title = selectedFile.name;
    const tfImg = this.tfImageRef.current;

    (async function() {
      reader.onload = await function(event) {
        tfImg.src = event.target.result;
        imgtag.style.backgroundImage = `url(${event.target.result})`;
      };
      reader.readAsDataURL(selectedFile);
    })().then(async () => {
      this.getPredictions(tfImg);
    });
  };

  freezeFrame(video, canvas) {
    const img = this.divImageRef.current;
    const tfImg = this.tfImageRef.current;

    canvas.getContext("2d").drawImage(video, 0, 0, 640, 480);

    img.src = canvas.toDataURL();
    img.style.backgroundImage = `url(${canvas.toDataURL()})`;
    tfImg.src = canvas.toDataURL();
  }

  getPredictions = async img => {
    this.setState({ isLoading: true });
    const predictions = await diagnose(img);
    this.setState({
      predictions,
      isLoading: false
    });
  };

  render() {
    const spinnerText = "Loading model and analysing data...";
    const particlesParams = {
      particles: {
        number: {
          value: 70,
          density: {
            enable: true,
            value_area: 800
          }
        },
        size: {
          value: 3
        }
      },
      interactivity: {
        events: {
          onhover: {
            enable: true,
            mode: "repulse"
          }
        }
      }
    };
    return (
      <div className="analyzer-container">
        <Particles className="particles" params={particlesParams} />

        <h1 className="classifier-title"> Demo Skin Analyzer App - SKAN </h1>
        <Logo />

        <div className="uploadDiv">
          <div className="imgAndPalette">
            {this.state.showVideo ? (
              <Modal show={this.state.showVideo}>
                <Video
                  closeVideo={this.closeVideo}
                  diagnosePictureTaken={this.getPredictions}
                  freezeFrame={this.freezeFrame}
                />
              </Modal>
            ) : null}

            {this.state.showRealTime ? (
              <Modal show={this.state.showRealTime}>
                <RealTime
                  closeRealTime={this.closeRealTime}
                  diagnose={this.getPredictions}
                  freezeFrame={this.freezeFrame}
                />
              </Modal>
            ) : null}
            <div
              className="image-div"
              style={{ backgroundImage: `url(${sampleImg})` }}
              id="div-image"
              ref={this.divImageRef}
            >
              {this.state.isLoading && <Spinner text={spinnerText} />}
            </div>
          </div>
          <PredictionList predictions={this.state.predictions} />
          <div className="button-div">
            <button
              onClick={() => {
                this.fileInput.click();
              }}
            >
              <FaUpload /> <span>Upload</span>
            </button>
            <button id="snap" onClick={() => this.showVideo()}>
              <FaCameraRetro /> <span>Take Photo</span>
            </button>

            <button id="real-time" onClick={() => this.showRealTime()}>
              <FaVideo /> <span>Real-Time</span>
            </button>
          </div>
          <div className="disclaimer">
            <p>
              Note: This is a prototype for experimental purposes only and is
              not a qualified medical opinion.
            </p>
            <p>
              In case of any health related emergencies, please reach out to
              your medical health provider immediately.{" "}
            </p>
          </div>
        </div>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this.fileSelectorHandler}
          ref={fileInput => (this.fileInput = fileInput)}
        />
        <img
          src={sampleImg}
          className="tf-img"
          id="tf-image"
          alt="TF Image"
          ref={this.tfImageRef}
        />
      </div>
    );
  }
}
