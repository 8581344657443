import React from "react";
import ReactTooltip from "react-tooltip";

import "./prediction-list.styles.scss";

class PredictionList extends React.Component {
  constructor(props) {
    super(props);
  }

  showToolTip(e) {
    e.preventDefault();
  }

  render() {
    let predictionList = this.props.predictions;
    if (Array.isArray(predictionList)) {
      predictionList = predictionList.map(prediction => {
        return (
          <li key={prediction.className}>
            <a
              href=""
              ref={this.predictionRef}
              data-tip
              data-for={prediction.className}
              onClick={e => {
                this.showToolTip(e);
              }}
            >
              {prediction.className}:
              {(prediction.probability * 100).toFixed(6) + "%"}
            </a>
            <ReactTooltip
              id={prediction.className}
              aria-haspopup="true"
              role="example"
              effect="solid"
              delayHide={400}
              delayShow={400}
              delayUpdate={400}
              place={"right"}
              border={true}
              type={"info"}
            >
              <h5>{prediction.className}</h5>
              <hr />
              <p>{prediction.description}</p>
            </ReactTooltip>
          </li>
        );
      });
    }

    return (
      <div className="predictions-container">
        <ol className="rectangle-list">{predictionList}</ol>
      </div>
    );
  }
}

export default PredictionList;
