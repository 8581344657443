import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios/index";
import Cookies from "universal-cookie";
import { v4 as uuid } from "uuid";

import Message from "./Message";
import Card from "./Card";
import QuickReplies from "./QuickReplies";
import logo from "../../images/logo.png";

const cookies = new Cookies();

class Chatbot extends Component {
  messagesEnd;
  talkInput;
  node;

  constructor(props) {
    super(props);

    this._handleInputKeyPress = this._handleInputKeyPress.bind(this);
    this._handleUserTextChange = this._handleUserTextChange.bind(this);
    this._handleButtonClick = this._handleButtonClick.bind(this);

    this._handleQuickReplyPayload = this._handleQuickReplyPayload.bind(this);

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.state = {
      userText: "",
      messages: [],
      showBot: false,
      shopWelcomeSent: false,
      landingPageWelcomeSent: false,
      clientToken: false,
      regenerateToken: 0
    };
    if (cookies.get("userID") === undefined) {
      cookies.set("userID", uuid(), { path: "/" });
    }
  }

  async df_text_query(text) {
    let says = {
      speaks: "user",
      msg: {
        text: {
          text: text
        }
      }
    };
    this.setState({ messages: [...this.state.messages, says] });

    const request = {
      queryInput: {
        text: {
          text: text,
          languageCode: "en-US"
        }
      }
    };

    await this.df_client_call(request);
  }

  async df_event_query(event) {
    const request = {
      queryInput: {
        event: {
          name: event,
          languageCode: "en-US"
        }
      }
    };

    await this.df_client_call(request);
  }

  async df_client_call(request) {
    try {
      if (this.state.clientToken === false) {
        const res = await axios.get("/api/get_client_token");
        this.setState({ clientToken: res.data.token });
      }

      var config = {
        headers: {
          Authorization: "Bearer " + this.state.clientToken,
          "Content-Type": "application/json; charset=utf-8"
        }
      };

      const res = await axios.post(
        "https://dialogflow.googleapis.com/v2/projects/" +
          process.env.REACT_APP_GOOGLE_PROJECT_ID +
          "/agent/sessions/" +
          process.env.REACT_APP_DF_SESSION_ID +
          cookies.get("userID") +
          ":detectIntent",
        request,
        config
      );

      let says = {};

      if (res.data.queryResult.fulfillmentMessages) {
        for (let msg of res.data.queryResult.fulfillmentMessages) {
          says = {
            speaks: "bot",
            msg: msg
          };
          this.setState({ messages: [...this.state.messages, says] });
        }
      }
    } catch (e) {
      if (e.response.status === 401 && this.state.regenerateToken < 1) {
        this.setState({ clientToken: false, regenerateToken: 1 });
        this.df_client_call(request);
      } else {
        let says = {
          speaks: "bot",
          msg: {
            text: {
              text:
                "I'm having some technical issues, so I need to terminate. Please try again later."
            }
          }
        };
        this.setState({ messages: [...this.state.messages, says] });
        let that = this;
        setTimeout(function() {
          that.setState({ showBot: false });
        }, 2000);
      }
    }
  }

  resolveAfterXSeconds(x) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, x * 1000);
    });
  }

  async componentDidMount() {
    if (
      window.location.pathname === "/DISABLE_contact" &&
      !this.state.shopWelcomeSent
    ) {
      await this.resolveAfterXSeconds(1);
      this.df_event_query("CONTACTUS_BIORHYTHM");
      this.setState({ shopWelcomeSent: true, showBot: true });
    }

    this.props.history.listen(() => {
      if (
        this.props.history.location.pathname === "/DISABLE_contact" &&
        !this.state.shopWelcomeSent
      ) {
        this.df_event_query("CONTACTUS_BIORHYTHM");
        this.setState({ shopWelcomeSent: true, showBot: true });
      }
    });

    if (
      window.location.pathname === "/contact" &&
      !this.state.landingPageWelcomeSent
    ) {
      await this.resolveAfterXSeconds(1);
      this.df_event_query("WELCOME_BIORHYTHM");
      this.setState({
        landingPageWelcomeSent: true
      });
    }
  }

  componentDidUpdate() {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    if (this.talkInput) {
      this.talkInput.focus();
    }
  }

  async show(event) {
    event.preventDefault();
    event.stopPropagation();

    document.addEventListener("click", this.handleOutsideClick, false);

    this.setState({ showBot: true });

    if (
      window.location.pathname === "/" &&
      !this.state.landingPageWelcomeSent
    ) {
      await this.resolveAfterXSeconds(1);
      this.df_event_query("WELCOME_BIORHYTHM");
      this.setState({ landingPageWelcomeSent: true });
    }
  }

  hide(event) {
    event.preventDefault();
    event.stopPropagation();

    document.removeEventListener("click", this.handleOutsideClick, false);

    this.setState({ showBot: false });
  }

  handleOutsideClick(event) {
    if (this.node.contains(event.target)) {
      return;
    }

    this.hide(event);
  }

  _handleQuickReplyPayload(event, payload, text) {
    event.preventDefault();
    event.stopPropagation();

    switch (payload) {
      case "contact_yes":
        this.df_event_query("CONTACT_YES");
        break;
      case "contact_no":
        this.df_event_query("CONTACT_NO");
        break;
      case "training_masterclass":
        this.df_event_query("MASTERCLASS");
        break;
      default:
        this.df_text_query(text);
        break;
    }
  }

  renderCards(cards) {
    return cards.map((card, i) => <Card key={i} payload={card} />);
  }

  renderOneMessage(message, i, isLastMessage) {
    if (message.msg && message.msg.text && message.msg.text.text) {
      return (
        <Message
          key={i}
          speaks={message.speaks}
          text={message.msg.text.text}
          isLastMessage={isLastMessage}
        />
      );
    } else if (
      message.msg &&
      message.msg.payload &&
      message.msg.payload.cards
    ) {
      return (
        <div key={i}>
          <div className="card-panel grey lighten-5 z-depth-1">
            <div style={{ overflow: "hidden" }}>
              <div className="col s2">
                <a
                  href="/"
                  className="btn-floating btn-large waves-effect waves-light red"
                >
                  {message.speaks}
                </a>
              </div>
              <div style={{ overflow: "auto", overflowY: "scroll" }}>
                <div
                  style={{
                    height: 300,
                    width: message.msg.payload.cards.length * 270
                  }}
                >
                  {this.renderCards(message.msg.payload.cards)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      message.msg &&
      message.msg.payload &&
      message.msg.payload.quick_replies
    ) {
      return (
        <QuickReplies
          text={message.msg.payload.text ? message.msg.payload.text : null}
          key={i}
          replyClick={this._handleQuickReplyPayload}
          speaks={message.speaks}
          payload={message.msg.payload.quick_replies}
        />
      );
    }
  }

  renderMessages(returnedMessages) {
    if (returnedMessages) {
      return returnedMessages.map((message, i) => {
        let isLastMessage = false;
        if (returnedMessages.length === i + 1) {
          isLastMessage = true;
        }
        return this.renderOneMessage(message, i, isLastMessage);
      });
    } else {
      return null;
    }
  }

  _handleInputKeyPress(e) {
    if (e.key === "Enter") {
      this.df_text_query(e.target.value);
      this.setState({ userText: "" });
      e.target.value = "";
    }
  }

  _handleUserTextChange(e) {
    this.setState({ userText: e.target.value });
  }

  _handleButtonClick(e) {
    this.df_text_query(this.state.userText);
    this.setState({ userText: "" });
  }

  render() {
    if (this.state.showBot) {
      return (
        <div
          className="grey lighten-3"
          ref={node => {
            this.node = node;
          }}
          style={{
            minHeight: 500,
            maxHeight: 470,
            width: 340,
            paddingRight: 0,
            position: "fixed",
            zIndex: 1000,
            bottom: 80,
            right: 25,
            border: "1px solid lightgray"
          }}
        >
          <nav onClick={this.hide}>
            <div className="light-blue lighten-4 nav-wrapper row">
              <div col s8>
                <img
                  className="left"
                  src={logo}
                  alt="Unsplashed background img 1"
                  width="60"
                  height="60"
                />
              </div>

              <ul id="nav-mobile" className="right col s2">
                <li>
                  <i className="material-icons prefix black-text">
                    keyboard_arrow_down
                  </i>
                </li>
              </ul>
            </div>
          </nav>

          <div
            id="chatbot"
            style={{
              minHeight: 388,
              maxHeight: 388,
              width: "100%",
              overflow: "auto"
            }}
          >
            {this.renderMessages(this.state.messages)}
            <div
              ref={el => {
                this.messagesEnd = el;
              }}
              style={{ float: "left", clear: "both" }}
            />
          </div>
          <div
            className="row"
            style={{
              margin: 0,
              backgroundColor: "white",
              padding: "1%",
              width: "100%"
            }}
          >
            <div className="col s10">
              <input
                value={this.state.userText}
                autoFocus
                style={{
                  margin: 0,
                  backgroundColor: "white",
                  border: 1,
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  width: "100%"
                }}
                ref={input => {
                  this.talkInput = input;
                }}
                placeholder="Type a message.."
                onKeyPress={this._handleInputKeyPress}
                onChange={this._handleUserTextChange}
                id="user_says"
                type="text"
              />
            </div>
            <div className="col s2">
              <i
                className="material-icons right"
                onClick={this._handleButtonClick}
              >
                send
              </i>
            </div>

            <div
              className="right fixed-action-btn row"
              onClick={this.hide}
              style={{
                paddingRight: 10
              }}
            >
              <a className="btn-floating btn-large blue">
                <i className="large material-icons">close</i>
              </a>
              <ul id="nav-mobile" className="col s2">
                <li>
                  <i
                    className="material-icons prefix black-text"
                    onClick={this.hide}
                  >
                    close
                  </i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="right fixed-action-btn"
          onClick={this.show}
          style={{
            paddingRight: 10,
            position: "-webkit - sticky",
            position: "sticky",
            bottom: 50
          }}
        >
          <a className="btn-floating btn-large blue">
            <i className="large material-icons">chat_bubble</i>
          </a>
          <ul id="nav-mobile" className="col s2">
            <li>
              <i
                className="material-icons prefix black-text"
                onClick={this.show}
              >
                chat_bubble
              </i>
            </li>
          </ul>
          <div
            ref={el => {
              this.messagesEnd = el;
            }}
          />
        </div>
      );
    }
  }
}

export default withRouter(Chatbot);
