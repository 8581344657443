import React from "react";

import techstack from "../../images/techstack.png";

const Services = () => (
  <div
    className="card-panel container light-blue lighten-5"
    style={{
      marginLeft: "auto",
      marginRight: "auto",
      padding: "5px",
      opacity: "0.9"
    }}
  >
    <div className="row">
      <div className="col s12">
        <p>
          We provide full stack development services. This includes gathering
          requirements, design, development, testing, deployment, and technical
          support.
        </p>
        <p>
          Some of the technologies we’ re currently using include Google’ s
          DialogFlow platform for NLP, Cloud based Databases such as mongoDB,
          Spring Boot, and latest JavaScript frameworks as with React, Redux,
          Node.js and Express.js!
        </p>
        <br />
      </div>
    </div>
    <div className="col s12 m8 offset-m2 l6 offset-l3">
      <div className="card-panel grey lighten-5 z-depth-1">
        <div className="row valign-wrapper">
          <img src={techstack} alt="techstack-img" className="responsive-img" />
        </div>
      </div>
    </div>
  </div>
);

export default Services;
