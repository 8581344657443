export const INITIAL_STATE = {
  sections: [
    {
      title: 'chatbots',
      imageUrl: 'https://journal.jp.fujitsu.com/en/2017/11/29/01/img/20171129_01_index_pic_1.jpg',
      size: 'large',
      id: 4,
      linkUrl: 'shop/chatbots'
    }
  ]
};

const directoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default directoryReducer;
