import React, { Component } from "react";

import TypingIndicator from "./TypingIndicator";
import bot from "../../images/bot.png";
import guest from "../../images/guest.png";
import "./style.css";

class Message extends Component {
  constructor(props) {
    super(props);

    this.showTypingIndicatorForXSeconds = this.showTypingIndicatorForXSeconds.bind(
      this
    );

    this.state = {
      indicatorOn: false
    };
  }

  async showTypingIndicatorForXSeconds(duration) {
    this.setState({
      indicatorOn: true
    });

    await setTimeout(() => {
      this.setState({
        indicatorOn: false
      });
    }, duration * 1000);
  }

  async componentDidMount() {
    if (this.props.speaks === "bot" && this.props.isLastMessage) {
      await this.showTypingIndicatorForXSeconds(2);
    }
  }

  render() {
    return (
      <div className="col s12 m8 offset-m2 l6 offset-l3">
        <div className="card-panel grey lighten-5 z-depth-2">
          {this.props.speaks === "bot" && this.state.indicatorOn && (
            <TypingIndicator />
          )}

          {this.props.speaks === "bot" && !this.state.indicatorOn && (
            <div className="row">
              <div className="col s3">
                <img
                  className="circle left"
                  src={bot}
                  alt="bot img"
                  width="40"
                  height="40"
                />
              </div>
              <div className="col s9 talk-bubble tri-right left-top">
                <div className="talktext">
                  <p>{this.props.text}</p>
                </div>
              </div>
            </div>
          )}
          {this.props.speaks === "user" && (
            <div className="row">
              <div className="col s9 talk-bubble tri-right right-in">
                <div className="talktext">
                  <p> {this.props.text}</p>
                </div>
              </div>
              <div className="col s3">
                <img
                  className="circle right"
                  src={guest}
                  alt="guest img"
                  width="40"
                  height="40"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Message;
