import React from 'react';

import bot from '../../images/bot.png';
import './style.css';

const TypingIndicator = (props) => {
  return (
    <div className="col s12 m8 offset-m2 l6 offset-l3">
      <div className="row">
        <div className="col s3">
          <img
            className="circle left"
            src={bot}
            alt="bot img"
            width="40"
            height="40"
          />
        </div>
        <div className="col s9 talk-bubble tri-right left-top">
          <div className="talktext">
            <div className="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );};

export default TypingIndicator;
