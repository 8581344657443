import React from 'react';
import Tilt from 'react-tilt';

import dr from './dr.png';
import './Logo.css';

const logo = (props) => {
  return (
    <div className="logo-container ma4 m0t">
      <Tilt className="Tilt br2 shadow-2" options={{ max: 55 }} >
        <div className="Tilt-inner pa3">
          <img src={dr} alt="Doctor logo" width="80" />
        </div>
      </Tilt>
    </div>
  );
};

export default logo;
