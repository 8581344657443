import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Button from "@material-ui/core/Button";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: "0",
    padding: "0",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: 400,
    height: 350
  },
  gridTile: {
    padding: "0",
    margin: "0",
    width: "10px"
  },
  amount: {
    padding: 0,
    margin: 0,
    width: 80,
    height: "80px !important"
  },
  other: {
    marginTop: 50,
    height: "80px !important"
  }
}));

export default function DonationOptions(props) {
  const classes = useStyles();
  const amounts = [2.75, 5, 10, 20, 30, 50, 100];

  return (
    <div className={classes.root}>
      <GridList cellHeight={160} className={classes.gridList} cols={4}>
        {amounts.map(amount => (
          <GridListTile key={amount} cols={1} className={classes.gridTile}>
            <Button
              variant="outlined"
              onClick={() => props.setAmount(amount)}
              className={classes.amount}
            >
              {`$${amount}`}
            </Button>
          </GridListTile>
        ))}
        <FormControl fullWidth variant="outlined">
          <InputLabel
            htmlFor="outlined-adornment-amount"
            className={classes.other}
          >
            Other
          </InputLabel>
          <OutlinedInput
            className={classes.other}
            id="outlined-adornment-amount"
            onChange={e => props.setAmount(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
      </GridList>
    </div>
  );
}
