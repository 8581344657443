import React from 'react';
import { Link } from 'react-router-dom';

import ProductsMenu from "./products-menu/products-menu.component";
import background4 from "../images/background4.jpg";
import logo from "../images/logo.png";
import Products from './pages/Products';

const Header = () => (
  <nav className="grey lighten-4" role="navigation">
    <div className="nav-wrapper">
      <Link
        id="logo-container"
        to={"/"}
        className="brand-logo right"
        style={{ fontSize: "1.1em", paddingRight: "4px" }}
      >
        BioRhythm Technologies
      </Link>
      <ul className="left hide-on-med-and-down">
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        <li>
          <Link to={"/services"}>Services</Link>
        </li>

        <li>
          <a target="_blank" href="https://medium.com/biorhythm-technologies">
            Blog
          </a>
        </li>
        <li>
          <Link to={"/contact"}>Contact Us</Link>
        </li>
        <li>
          <Link to={"/donate"}>Donate</Link>
        </li>
        <li>
          <a>
            <ProductsMenu />
          </a>
        </li>
      </ul>

      <ul id="slide-out" className="sidenav">
        <li>
          <div className="user-view">
            <div className="background">
              <img src={background4} />
            </div>
            <a href="#user">
              <img className="circle" src={logo} />
            </a>
          </div>
        </li>
        <li>
          <Link to={"/"} className="sidenav-close">
            Home
          </Link>
        </li>
        <li>
          <div className="divider" />
        </li>
        <li className="sidenav-close">
          <Link to={"/services"}>Services</Link>
        </li>
        <li>
          <div className="divider" />
        </li>
        <li className="sidenav-close">
          <a target="_blank" href="https://medium.com/biorhythm-technologies">
            Blog
          </a>
        </li>
        <li>
          <div className="divider" />
        </li>
        <li className="sidenav-close">
          <Link to={"/contact"}>Contact Us</Link>
        </li>
        <li>
          <div className="divider" />
        </li>
        <li className="sidenav-close">
          <Link to={"/donate"}>Donate</Link>
        </li>
        <li>
          <div className="divider" />
        </li>
        <li className="sidenav-close">
          <a>
            <ProductsMenu />
          </a>
        </li>
        <li>
          <div className="divider" />
        </li>
      </ul>
      <a href="#" data-target="slide-out" className="sidenav-trigger">
        <i className="material-icons">menu</i>
      </a>
    </div>
  </nav>
);

export default Header;
