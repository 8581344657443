import React, { createContext, useEffect } from "react";
import useSettingState from "../hooks/useSettingState";

export const RequestTypeContext = createContext();

export function RequestTypeProvider(props) {
  const initialSettings = JSON.parse(
    window.localStorage.getItem("settings") ||
      '{"searchSimilarityBy": "answer", "numberResultsToReturn": "1", "answerOnly": "true"}'
  );

  const {
    settings,
    setSearchSimilarityBy,
    setNumberResultsToReturn,
    setAnswerOnly
  } = useSettingState(initialSettings);

  const { searchSimilarityBy, numberResultsToReturn, answerOnly } = settings;

  useEffect(() => {
    window.localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  return (
    <RequestTypeContext.Provider
      value={{
        searchSimilarityBy,
        setSearchSimilarityBy,
        numberResultsToReturn,
        setNumberResultsToReturn,
        answerOnly,
        setAnswerOnly
      }}
    >
      {props.children}
    </RequestTypeContext.Provider>
  );
}

export const withRequestTypeContext = Component => props => (
  <RequestTypeContext.Consumer>
    {value => <Component requestTypeContext={value} {...props} />}
  </RequestTypeContext.Consumer>
);
