import React, { createContext, useEffect } from "react";

import useToggleState from "../hooks/useToggleState";

export const ThemeContext = createContext();

export function ThemeProvider(props) {
  const initialTheme = JSON.parse(
    window.localStorage.getItem("theme") || '{"isDarkMode": false}'
  );

  const [isDarkMode, toggleTheme] = useToggleState(initialTheme.isDarkMode);

  useEffect(() => {
    window.localStorage.setItem(
      "theme",
      JSON.stringify({ isDarkMode: isDarkMode })
    );
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
