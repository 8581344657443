import React from 'react';

const About = () => (
  <div>
    <h1>About us!</h1>
    <p>
      BioRhythm Technologies is an AI-focused consulting firm that builds
      machine learning solutions to maximize business success for companies and
      organizations across wide range of industries. Areas of focus include
      computer vision, NLP, and predictive analytics.
    </p>
  </div>
);

export default About;
